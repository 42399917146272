import { Link } from "@StarberryUtils";
import PropTypes from "prop-types";
import React,{useEffect,  useState} from "react";
import {Col, Row, Container,Navbar,Nav, Tabs, Tab, Dropdown } from 'react-bootstrap';
import VideoCard from '../VideoCard/VideoCard';
import  "./MgFavourites.scss";
import favImg01 from "../../../images/fav-img01.jpg"
import favImg02 from "../../../images/fav-img02.jpg"
import favImg03 from "../../../images/fav-img03.jpg"
import favImg04 from "../../../images/fav-img04.jpg"
import ReactMarkdown from "react-markdown"
import { EventDate, EventShortDate, EventEndDate } from "../../common/site/functions";
import EventsCalender from "./UpcomingEventCalender";
import $ from "jquery";
import {isMobile} from "react-device-detect";
import moment from 'moment';

const MgFavouritesList=(props)=>{
   
  const [key, setKey] = useState('restaurant-and-bars');
  const [ eventdate, setEventdate ] = useState('');
  const [ eventcat, setEventcat ] = useState('');
  const [ month, setMonth ] = useState(moment().format('MMMM YYYY'));
  const [ eventcatname, setEventcatname ] = useState('');
  var eventslist    = props.data;
  var allevents     = props.data;
  var eventscatlist = '';
  var datelist      = props.data;
  //console.log("eventslist",eventslist)
  if(eventdate) {
    eventslist = props.data.filter(obj => obj.Event_Start_Date_Only <= eventdate && obj.Event_End_Date_Only >= eventdate )
  }
  if(eventslist && eventslist.length > 0) {
      allevents = eventslist
  }
  if(eventcat) {
      datelist      = props.data.filter(obj => obj.Select_Events_Categories.includes(eventcat))
      eventscatlist = props.data.filter(obj => obj.Select_Events_Categories.includes(eventcat))    
  }
  if(eventcat && eventdate) {
    eventscatlist = props.data.filter(obj => (obj.Event_Start_Date <= eventdate && obj.Select_Events_Categories.includes(eventcat)) && (obj.Event_End_Date >= eventdate && obj.Select_Events_Categories.includes(eventcat) ))   
    if((eventslist.length > 0 && eventscatlist.length == 0 ) || (eventslist.length == 0 && eventscatlist.length == 0)) {
      eventscatlist = props.data.filter(obj => obj.Select_Events_Categories.includes(eventcat))  
      setEventdate('');
      eventslist = '';
    }
  }
  if(eventscatlist && eventscatlist.length > 0) {
    allevents = eventscatlist
  }
  //console.log('eveeeeeee', eventscatlist, eventslist, eventcat)
  if(eventdate && !props.isResponsive) {
    $('html, body').animate({
      scrollTop: $(".mg-fav-landing").offset().top-150
    }, 1000);
  }
  if(eventdate && props.isResponsive) {
    $('html, body').animate({
      scrollTop: $(".mg-fav-landing .events-head").offset().top-100
    }, 1000);
  }
  var noeventstxt= "";
  if((eventslist.length == 0)) {
    noeventstxt = "Unfortunately, there are no events on the selected date. Please try a different date."
  }
  if(eventscatlist.length == 0 && eventcat) {
    noeventstxt = "Unfortunately, there are no events on the selected category. Please try a different category."
  }
  if(allevents.length == 0 || allevents.filter(c=>moment(c.Event_Start_Date).format('MMMM YYYY') == month).length == 0) {
    noeventstxt="";
  }
  //console.log('month', month)
  allevents.sort((a, b) => new Date(a['Event_Start_Date_Only'].replace(/-/g, ' ')) - new Date(b['Event_Start_Date_Only'].replace(/-/g, ' ')));
  return (
    <section className="mg-favourites mg-fav-landing">
      <Container className="tab-content">
        <Row className="events--row">
        <Col xl={7}>
        <div className="events-head">
            <h2>Upcoming Events</h2>
            {props.eventcategories ?
              <div className="area_drop_down events_drop_down cta_drop_down">
                <Dropdown>
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {eventcatname?eventcatname:'All Events'}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {eventcatname?
                    <Dropdown.Item><a onClick={()=>{setEventcat('');setEventcatname('');setEventdate('');}}>All Events</a></Dropdown.Item>
                    :""}
                    {props.eventcategories.map(({node}, i) => {
                      if(node.local_life_things_to_dos && node.local_life_things_to_dos.length > 0) {
                      return (
                        <Dropdown.Item><a onClick={()=>{setEventcat(node.strapiId);setEventcatname(node.Name);}}>{node.Name}</a></Dropdown.Item>
                      )
                      }
                    })}
                  </Dropdown.Menu>
                </Dropdown>

              </div> : ''
            }
        </div>
        {noeventstxt && 
            <h3 className="noeventslist">{noeventstxt}</h3>
        }
        {allevents.length == 0 || allevents.filter(obj => (moment(obj.Event_Start_Date).format('MMMM YYYY') == month) || (moment(obj.Event_End_Date).format('MMMM YYYY') == month)).length == 0 &&
        <h3 className="noeventslist"> Unfortunately, there are no events on the selected date. Please try a different date.</h3>
        }
   
        {allevents.length > 0 && allevents.filter(obj => (moment(obj.Event_Start_Date).format('MMMM YYYY') == month) || (moment(obj.Event_End_Date).format('MMMM YYYY') == month)).map((data, i) => {
        //console.log('data', data)
        let eat_in = ''
        if(data.Eat_in == true) {
          eat_in = "eat_in"
        }
        let takeaways = ''
        if(data.Takeaways == true) {
          takeaways = "takeaways"
        }

            return (
                <Col xl={12} className={`mg-list ${eat_in} ${takeaways} ${data.Event_Start_Date} ${eventdate}`}>
                    <Link to={`/about/loving-life/${props.url}/${data.URL}`} className="link event--link">
                        <div className={`favourite-item d-flex`}>
                            <figure>
                                {data.Tile_Image ? <img src={data.Tile_Image.childImageSharp.gatsbyImageData.images.fallback.src} alt={`${data.Name} - Martyn Gerrard`} /> : '' }
                                {data.Tile_Image_Label ? <span className="tag-abs text-uppercase">{data.Tile_Image_Label}</span> : '' }
                            </figure>
                            <div className="info">
                                <div className="info-heads">
                                <h4 className="info-title">{data.Name}</h4>
                                {eventslist && eventslist.length > 0 && eventdate ?
                                <span className="event-date">{props.isResponsive? EventShortDate(eventdate) : EventDate(eventdate)}</span>
                                :
                                <span className="event-date">{props.isResponsive? EventShortDate(data.Event_Start_Date) : EventDate(data.Event_Start_Date)}{data.Event_End_Date_Only > data.Event_Start_Date_Only? EventEndDate(data.Event_End_Date_Only):""}</span>
                                }
                                </div>
                                {<span className="add-txt small text-muted"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="#005480" class="bi bi-clock" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
                              </svg> {data?.Event_Start_Date_Time} - {data?.Event_End_Date_Time}</span>}
                                <ReactMarkdown source={data.Short_Description} allowDangerousHtml className=""/>
                            </div>
                        </div>
                    </Link>
                    
                </Col>
                
              );
        
        })}
        {/* {props.isResponsive && props.eventcontest && props.eventcontest.map(({node}, i) => {
              const left_image_url = node.Events_Contest.Left_Top_Image.internal.description ? node.Events_Contest.Left_Top_Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 
              const right_image_url = node.Events_Contest.Right_Bottom_Image.internal.description ? node.Events_Contest.Right_Bottom_Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 
              if(node.Events_Contest.Contest_Text) {
              return (
                <section className="events-contenst-block">
                    <div className="sec-content-block">
                      <div className="left-img">
                        <img src={left_image_url} />
                      </div>
                      <div className="text-block">
                        <h2>{node.Events_Contest.Contest_Text}</h2>
                      </div>
                      <div className="right-img">
                      <img src={right_image_url} /> 
                      </div>
                    </div>
                    <div className="block-btn">
                    <a class="btn" href={node.Events_Contest.Contest_CTA_Link}>{node.Events_Contest.Contest_CTA_Label}</a>
                    </div>
                </section>
              )
              }
            })} */}
        </Col>
        
        <Col xl={5} className={!props.isResponsive?`pr-0`:``}>
            <EventsCalender data={datelist} geteventdate={setEventdate} month={setMonth}/>
            {/* {!props.isResponsive && props.eventcontest && props.eventcontest.map(({node}, i) => {
              const left_image_url = node.Events_Contest.Left_Top_Image.internal.description ? node.Events_Contest.Left_Top_Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 
              const right_image_url = node.Events_Contest.Right_Bottom_Image.internal.description ? node.Events_Contest.Right_Bottom_Image.internal.description.replace("File ","").replace('"','').replace('"','') : ''; 
              if(node.Events_Contest.Contest_Text) {
              return (
                <section className="events-contenst-block">
                    <div className="sec-content-block">
                      <div className="left-img">
                        <img src={left_image_url} />
                      </div>
                      <div className="text-block">
                        <h2>{node.Events_Contest.Contest_Text}</h2>
                      </div>
                      <div className="right-img">
                      <img src={right_image_url} /> 
                      </div>
                    </div>
                    <div className="block-btn">
                    <a class="btn" href={node.Events_Contest.Contest_CTA_Link}>{node.Events_Contest.Contest_CTA_Label}</a>
                    </div>
                </section>
              )
              }
            })} */}
        </Col>
        </Row>
      </Container>
    </section>
  );
  }

export default MgFavouritesList
